<template>
  <div class="zee-frequency-container">
    <div class="zee-frequency-items">
      <div class="zee-frequency-items" v-for="(item, index) in items" :key="index">
        <div class="d-flex">
          <input @input="handleItemSelect" type="checkbox" :id="index" />
          <div class="mt-2 ml-3 fs-5 text-white font-weight-bold">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { id: 0, name: "Sunday", count: 0 },
        { id: 1, name: "Monday", count: 0 },
        { id: 2, name: "Tuesday", count: 0 },
        { id: 3, name: "Wednesday", count: 0 },
        { id: 4, name: "Thursday", count: 0 },
        { id: 5, name: "Friday", count: 0 },
        { id: 6, name: "Saturday", count: 0 },
      ],
      selected: [],
      dynamic: [],
      value1: [],
      checkedValues: [],
    };
  },
  props: {
    selectedDay: {
      type: Number,
    },
    customSelectedDays: {
      type: Array,
    },
  },
  methods: {
    handleItemSelect(e) {
      // this.value1 = [];
      if (!document.getElementById(+e.target.id).checked) {
        let index = this.selected.indexOf(+e.target.id);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(+e.target.id);
      }
      // console.log("this is count ", document.getElementById(+e.target.id).checked);
      let selected = [...new Set(this.selected)];
      // console.log("this is selected array", selected);
      for (var i = 0; i < selected.length; i++) {
        this.value1.push(this.items[selected[i]].name);
      }
      this.checkedValues = [];
      for (let j = 0; j < 7; j++) {
        if (document.getElementById(j).checked) {
          this.checkedValues.push(j);

          // console.log(document.getElementById(j).checked, j);
        } else {
          this.checkedValues.splice(j, 1);
          // console.log(document.getElementById(j).checked, j);
        }
        this.checkedValues = [...new Set(this.checkedValues)];
      }
      // console.log("this is checked values", this.checkedValues);
      this.$emit("select", this.value1);
    },
  },
  watch: {
    selectedDay: function () {
      let value = [];
      this.selectedDay - 1 !== null;

      for (let j = 0; j < 7; j++) {
        document.getElementById(j).checked = false;
        document.getElementById(j).disabled = false;
      }
      // this.customSelectedDays = []

      document.getElementById(this.selectedDay).checked = true;
      document.getElementById(this.selectedDay).disabled = true;
      this.dynamic.push(document.getElementById(this.selectedDay).id);  
      let selected = [...new Set(this.dynamic)];
      for (var i = 0; i < selected.length; i++) {
        value.push(this.items[selected[i]].name);
      }
      this.$emit("dynamic", value);
    },
  },
  mounted() {
    // console.log("this is in program frequency", this.selectedDay)
    document.getElementById(this.selectedDay).checked = true;
    document.getElementById(this.selectedDay).disabled = true;
    for(let i = 0; i<this.customSelectedDays.length; i++){
      document.getElementById(this.customSelectedDays[i]).checked = true;
    }
    for (let j = 0; j < 7; j++) {
      if (document.getElementById(j).checked) {
        this.checkedValues.push(j);
      } else {
        this.checkedValues.splice(j, 1);
      }
    }
    this.checkedValues = [...new Set(this.checkedValues)];
    // console.log("this is checked values", this.checkedValues);
    // this.selected = [...this.customSelectedDays]
  },
  beforeDestroy() {
    // console.log("unmounted");
    this.value1 = [];
    let weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // console.log("this is checked values", this.checkedValues);
    this.customSelectedDays = [...this.checkedValues, this.selectedDay];
    for (let i = 0; i < this.customSelectedDays.length; i++) {
      this.value1.push(weekDays[this.customSelectedDays[i]]);
      // console.log(weekDays[this.customSelectedDays[i]]);
    }
    this.value1 = [...new Set(this.value1)];
    // this.$emit("select", this.value1);
    // console.log("this is value1", this.value1);
    let data = JSON.parse(localStorage.getItem("programDetails"));
    data[0].programFrequency = [...this.value1];
    localStorage.setItem("programDetails", JSON.stringify(data));
  },
};
</script>

<style scoped>
.zee-frequency-container {
  width: 600px;
  margin-top: 24px;
  margin-left: 10px;
  border-radius: 8px;
  height: 130px;
  background-color: #6c757d;
}
.zee-frequency-items {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}
</style>
