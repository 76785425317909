<template>
  <div>
    <div
      class="zee-select-table-header-wrapper"
      :class="isProgramTable && 'zee-select-table-header-wrapper-program'"
      v-for="(item, index) in tableHeaderData"
      :key="index"
    >
      <div v-for="(item1, index) in item.columns" :key="index">
        <table class="zee-select-table-column">
          <tr>
            <th :class="{ expand: item1.expand }" class="zee-select-table-elem">
              <div class="d-flex justify-content-center align-items-center">
                {{ item1.name }}
              </div>
              <span class="tooltiptext" v-if="item1.startDate != undefined">
                <div>Start Date: {{item1.startDate.name}}</div>
                <div>End Date: {{item1.endDate.name}}</div>
                <!-- <div>Number of days: {{item.numOfDays}}</div> -->
            </span>
            </th>
            
          </tr>
        </table>
      </div>
    </div>

    <div
      class="zee-select-table-header-wrapper"
      :class="isProgramTable && 'zee-select-table-header-wrapper-program'"
      v-for="(item, index) in tableBodyData"
      :key="index"
    >
      <div v-for="(item1, index) in item.columns" :key="index">
        <table class="zee-select-table-column-body" :style="`${isSecondagesTable && index == 2 ? 'width: 138px !important;' : ''}
        ${isSecondagesTable && index == 0 ? 'min-width: 245px !important' : ''}`">
          <tr>
            <th
              :class="{
                active: index === 0 && hideFirst,
                color: item1.color,
                blue: item1.blue,
                red: item1.red,
                green: item1.green,
                hideThis:
                  item1.show || (bgTransparentOnEmpty && Object.keys(item1).length === 0),
              }"
              class="zee-select-table-elem-body"
            >
              <div
                :class="{ activeChild: index === 0 }"
                class="d-flex justify-content-center align-items-center"
              >
                {{ item1.name }}
              </div>
            </th>
          </tr>
        </table>
      </div>
      <div v-if="isSecondagesTable">
        <table class="zee-custom-table-input-component-wrapper">
          <tr id="parent_xyz">
            <div class="zee-custom-action"></div>
          </tr>
        </table>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SelectTable",
  props: {
    tableHeaderData: {
      type: Array,
    },
    tableBodyData: {
      type: Array,
    },
    changeColor: {
      type: Boolean,
      default: false,
    },
    hideFirst: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    isProgramTable: {
      type: Boolean,
      default: false,
    },
    isSecondagesTable: {
      type: Boolean,
      default: false,
    },
    bgTransparentOnEmpty: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.zee-select-table-column {
  position: relative;
  margin: 1rem;
}
.zee-select-table-column-body {
  margin: 1rem;
}

.zee-select-table-header-wrapper {
  display: flex;
  /* justify-content: space-around; */
  padding: 0.5rem;
  width: 100%;
  padding-left: 0%;
  padding-right: 0%;
}

.zee-select-table-header-wrapper-program div:first-child .zee-select-table-elem,
.zee-select-table-header-wrapper-program div:first-child .zee-select-table-elem-body {
  width: 240px !important;
}

.zee-select-table-elem {
  width: 138px;
  height: 30px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  background-color: #6c757d;
  color: #ffffff;
  font-weight: bold;
  outline: none;
  border: none;
}

.zee-select-table-elem-body {
  width: 138px;
  height: 30px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  background-color: #313131;
  color: #ffffff;
  font-weight: bold;
  outline: none;
  border: none;
}

.zee-custom-action {
  width: 138px;
  height: 30px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
}

.zee-select-table-elem-body-transparent {
  background-color: transparent !important;
}

.zee-select-table-column .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.zee-select-table-column:hover .tooltiptext {
  visibility: visible;
}

.active {
  background-color: #0dcaf0;
}

.blue {
  background-color: #0dcaf0;
  color: #000000;
}

.expand {
  width: 260px;
  margin: 2rem;
}

.color {
  background-color: #ffc107;
  border-radius: 8px;
}
.color1 {
  width: 400px;
  background-color: aquamarine;
}
.red {
  background-color: #ff5252;
}

.green {
  background-color: #6db056;
}

.hideThis {
  visibility: hidden;
}

.activeChild {
  font-size: 12px;
}
</style>
