<template>
  <div @click="handleClick" class="zee-container">
    <zee-screen-title
      :class="classname"
      :route="route"
      :title="title"
      :showIcon="showIcon"
    />
    <div class="zee-card-background">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ScreenTitle } from "../components";
export default {
  name: "Container",
  components: {
    "zee-screen-title": ScreenTitle,
  },
  props: {
    title: {
      type: String,
    },
    route: {
      type: String,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    classname: {
      type: String,
    },
  },
  methods: {
    handleClick(e) {
      let val = e.target.innerText;
      this.$emit("onClick", val);
    },
  },
};
</script>

<style scoped>
.zee-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  height: 100%;
}

.zee-card-background {
  padding: 3rem;
  background-color: #fafafa;
  width: 90%;
  margin-top: 18px;
  border-radius: 15px;
}
</style>
