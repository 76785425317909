<template>
  <div>
    <multiselect ref="multiselect" v-model="value" :options="options" :multiple="true" :close-on-select="false"
      :clear-on-select="false" :preserve-search="true" :placeholder="placeholder" label="name" track-by="name"
      :tabindex="tabindex" :preselect-first="false" :show-labels="false" :openDirection="'below'" class="tableDropdown"
      :class="isProgramTable && 'programTableDropdown'" @input="change($event)">
      <!-- @close="close($event)" -->
      <template slot="selection" slot-scope="{ values }">
        <span v-for="(item, index) in values" :key="index">
          <span class="multiselect__single"> {{ values[index].id }},</span>
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc" :for="props.option.name + props.option.value">
          <input class="form-check-input" type="checkbox" :id="props.option.name + props.option.value"
            :disabled="props.option.$isDisabled" :checked="props.option.value" />
          <!-- value.find((o) => o.name === props.option.name) !== undefined -->
          <span :class="isProgramTable && 'program_option__des'" class="option__title text-white ml-4">{{
              props.option.name
          }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: [
    "maximum",
    "options",
    "placeholder",
    "showPlaceHoaderOnly",
    "editsOptions",
    "isProgramTable",
    "resetAfter",
    "tabindex",
  ],
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedOption: [],
      value: [],
    };
  },
  methods: {
    change(event) {
      this.options.forEach((element) => {
        event.forEach((el) => {
          if (element.name === el.name) {
            element.value = true;
          }
        });
        if (!event.some((el) => el.name === element.name)) {
          element.value = false;
        }
      });

      if (event.length === this.maximum) {
        this.options.forEach((element) => {
          if (!event.some((el) => el.name === element.name)) {
            element.$isDisabled = true;
          }
        });
      } else {
        this.options.forEach((element) => {
          element.$isDisabled = false;
        });
      }
      this.$emit(
        "getSelectedHeader",
        event.map((el) => ({ label: el.name, key: el.key }))
      );
    },
  },
  created() {
    // if (this.isProgramTable) {
    //   this.options = this.options.map((el) => {
    //     return {
    //       ...el,
    //       value: false
    //     }
    //   });
    // }
    this.value = this.options.filter((el) => el.value === true);
    this.$emit(
      "getSelectedHeader",
      this.value.map((el) => ({ label: el.name, key: el.key }))
    );
  },
};
</script>
<style>
.tableDropdown.multiselect {
  min-height: 40px;
  min-width: 240px;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 8px 0 #c9ced5;
  border-radius: 8px;
  background-color: #6c757d;
  z-index: tabindex;
}

.programTableDropdown.multiselect {
  min-height: 30px !important;
  height: 100% !important;
  min-width: 138px !important;
  width: 100% !important;
}

.programTableDropdown .multiselect__tags {
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.programTableDropdown .multiselect__tags span {
  display: inline-block;
  padding-top: 2.5px;
  padding-left: 0 !important;
}

.programTableDropdown .multiselect__single {
  font-size: 12px !important;
  margin-bottom: 0 !important;
}

.programTableDropdown .multiselect__placeholder {
  font-size: 12px !important;
  margin-top: 2.5px !important;
  padding-left: 0 !important;
}

.multiselect__single {
  width: auto;
  font-size: 16px;
  background-color: #6c757d;
  /* background: #; */
  color: #ffffff;
}

.multiselect__select {
  width: 36px;
  height: 100%;
}

.tableDropdown .multiselect__tags {
  min-height: 0px;
  width: 100px;
  margin-left: 10px;
  margin-top: 10px;
  font-family: ProximaNovaRegular;
  font-weight: 600;
  padding: 0px;
  border: none;
  background-color: #6c757d;
}

.multiselect__select:before {
  border-color: #050505 transparent transparent;
}

.multiselect__content-wrapper {
  background-color: #6c757d;
  top: 50px;
  border-radius: 8px;
}

.multiselect__option--selected,
.multiselect__option--group {
  color: #050505;
  background: #6c757d !important;
}

.multiselect__option {
  color: #ffffff;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}

.multiselect__input {
  background: transparent;
  width: 0% !important;
}

.multiselect__placeholder {
  padding-left: 5px;
  padding-top: 0px;
  margin: 0px;
  font-size: 16px;
  color: #ffffff;
}

.option__desc {
  display: flex;
  align-items: center;
}

.program_option__desc {
  font-size: 12px !important;
}

.checkbox-option {
  height: 24px;
  margin: 0px;
  margin-right: 20px;
  width: 24px;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
  border-color: #222a37;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}

.multiselect__option--selected.multiselect__option--highlight {
  color: #050505;
}

.multiselect__option--disabled {
  color: #989898;
}

label {
  cursor: pointer;
}
</style>