<template>
    <b-modal hide-footer no-close-on-backdrop hide-header no-fade v-model="modalShow" size="md" class="p-0">
        <div>
            <p class="title">{{ title }} </p>

            <div class="d-flex justify-content-center">
                <zee-button style="background-color: #6db056" @onClick="close"
                    title="Continue"></zee-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import {
    Button,
} from "../components";


export default {
    name: "ProgramPercentageInfoModal",
    components: {
        // "w-dropdown": Dropdown,
        "zee-button": Button,
    },
    props: {
        modalShow: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "You have entered secondages more than 100% and you can go up to 139%."
        }
    },
    data() {
        return {
            selectedDate: "",
            numOfWeeksDropdown: [],
            isI: false,
            isDateSelected: false,
            selectedWeek: {},
            acdInputValue: ""
        };
    },
    methods: {
        close() {
            this.$emit('closeModal')
        },
    }
};
</script>
<style>
.modal.show .modal-dialog {
    margin-top: 0px;
}
</style>
<style scoped>
input {
    border: none;
    border-color: #c6ccd6;
    margin-left: 0;
    background-color: #eff5ff;
    box-shadow: none;
    color: #222a37;
    font-size: 16px;
    font-family: ProximaNovaBold;
}

.input-wrapper {
    height: 60px;
    font-size: 16px;
    padding-left: 21px;
}

input:focus {
    background-color: #eff5ff;
    outline: none;
}

.title {
    font-size: 20px;
    font-family: ProximaNovaBold;
    line-height: 1.56;
    color: #222a37;
    text-align: center;
    padding: 16px;
    white-space: pre-wrap;
}

.subtitle {
    font-family: ProximaNovaRegular;
    font-size: 16px;
    line-height: 1.38;
    color: #9aafd4;
    margin-bottom: 24px;
}

.bg-red {
    background-color: #ff5a5a !important;
}

.change-mind {
    margin-top: 26px;
    font-family: ProximaNovaRegular;
    font-size: 16px;
    color: #050505;
    text-decoration-line: underline;
    cursor: pointer;
}

.btn-effect {
    font-family: ProximaNovaRegular;
    background-color: #050505;
    color: #f3f3f3;
    border: none;
    text-align: left;
    font-size: 16px;
    padding-left: 20px;
    width: 208px !important;
    height: 60px !important;
    margin-top: 24px !important;
    object-fit: contain;
}

.btn-effect-disabled {
    opacity: 0.2;
}

.btn-effect i {
    font-size: 14px;
    float: right;
    margin-top: 7px;
    margin-right: 10px;
}
</style>
