<template>
  <div class="zee-screen-title">
    <zee-icon
      v-show="!showIcon"
      @click="handleClick(route)"
      class="zee-screen-title-icons"
      name="arrow-left"
    />
    <div>
      {{ title }}
    </div>
    <div class="ml-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons";

export default {
  name: "ScreenTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    route: {
      type: String,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "zee-icon": Icon,
  },
  methods: {
    handleClick(route) {
      console.log(route)
      if(route === '/solutions/zeetv/select-acds'){
        this.$router.push({
          name:"ZEETV Select ACDS",
          params:{data:'program-plan'}
        })
        return
      }
      this.$router.push(route);
    },
  },
};
</script>

<style>
.zee-screen-title {
  display: flex;
  width: 90%;
  font-size: 2.2rem;
  font-weight: bolder;
  margin-top: 8%;
  display: flex;
  align-items: center;
  color: #000;
}
.zee-screen-title-icons {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}
</style>
