<template>
  <b-modal
    hide-footer
    no-close-on-backdrop
    hide-header
    no-fade
    v-model="modalShow"
    size="md"
    class="p-0"
    >d
    <div>
      <zee-tabs title="Download Type" icon="pen">
        <zee-dropdown
          :options="downloadTypeOption"
          style="width: 200px"
          :selectedOption="selectedDownloaType"
          :placeHolder="'Select'"
          @input="handleTypeDropdownChange($event)"
        />
      </zee-tabs>

      <div class="d-flex justify-content-between">
        <zee-button
          style="background-color: #313131"
          @onClick="close"
          title="close"
        ></zee-button>
        <zee-button
          style="background-color: #6db056"
          @onClick="handleNextReq"
          :isDisable="disabledNextReqBtn"
          title="Download"
        ></zee-button>
      </div>
      {{ selectedDownloaType }}
    </div>
  </b-modal>
</template>

<script>
import { Tabs, Dropdown, Button } from "../components";
import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();

export default {
  name: "ProgramOutputDownloadModal",
  components: {
    // "w-dropdown": Dropdown,
    "zee-tabs": Tabs,
    "zee-button": Button,
    "zee-dropdown": Dropdown,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      downloadTypeOption: [
        { text: "Daily Summary", id: "DailySummary" },
        { text: "Date Wise Summary", id: "DatwWiseSummary" },
        { text: "Monthly Summary", id: "MonthlySummary" },
        { text: "Week wise Summary", id: "WeeklySummary" },
      ],
      selectedDownloaType: null,
    };
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    handleNextReq() {
      const type = this.selectedDownloaType.id;
      const fileName = this.selectedDownloaType.text;
      const runId = sessionStorage.getItem("run_id");

      zeeSolutions.getDownloadWeeklySummary(runId, fileName, type);

      this.close();
    },
    handleTypeDropdownChange(e) {
      this.selectedDownloaType = null;
      if (e.id != undefined) {
        this.selectedDownloaType = e;
      }
    },
  },
  computed: {
    disabledNextReqBtn() {
      return this.selectedDownloaType == null;
    },
  },
};
</script>
<style>
.modal.show .modal-dialog {
  margin-top: 0px;
}
</style>
<style scoped>
input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  color: #222a37;
  font-size: 16px;
  font-family: ProximaNovaBold;
}

.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}

input:focus {
  background-color: #eff5ff;
  outline: none;
}

.title {
  margin-top: 70px;
  font-size: 32px;
  font-family: ProximaNovaBold;
  line-height: 1.56;
  color: #222a37;
}

.subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 1.38;
  color: #9aafd4;
  margin-bottom: 24px;
}

.bg-red {
  background-color: #ff5a5a !important;
}

.change-mind {
  margin-top: 26px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #050505;
  text-decoration-line: underline;
  cursor: pointer;
}

.btn-effect {
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 208px !important;
  height: 60px !important;
  margin-top: 24px !important;
  object-fit: contain;
}

.btn-effect-disabled {
  opacity: 0.2;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
