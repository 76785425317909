import { HotifyApiHelper } from "../../../../services/helper/HotifyApiHelper";
const hotifyApiHelper = new HotifyApiHelper();

export class zeeTvSolutions {
  constructor() { }

  postUserInput(data) {
    const uri = `zee/userInput`;
    return hotifyApiHelper.post(uri, data);
  }

  getGeneratedPlan(runId) {
    const uri = `zee/generatePlan?runid=${runId}`;
    return hotifyApiHelper.get(uri);
  }

  postNewPlans(runId, data) {
    const uri = `zee/addNewProgram?runid=${runId}`;
    return hotifyApiHelper.post(uri, data);
  }

  getNewPlanResults(runId) {
    const uri = `zee/newProgramOutput?runid=${runId}`;
    return hotifyApiHelper.post(uri);
  }

  getDummyPrograms() {
    const uri = `zee/getShows`;
    return hotifyApiHelper.get(uri);
  }

  getMonthlyOutput(runId, requiredWeek) {
    const uri = `zee/monthlySummary?runid=${runId}&requiredWeek=${requiredWeek}`;
    return hotifyApiHelper.get(uri);
  }
  getCompetitorLaunchTargets(requiredWeek) {
    const uri = `zee/getCompetitorDistribution?programNumberOfWeeks=${requiredWeek}`;
    return hotifyApiHelper.get(uri);
  }
  getDpRecommendedTargets(weeks, maxGRP) {
    const uri = `zee/getDPRecommended?programNumberOfWeeks=${weeks}&maxTarget=${maxGRP}`;
    return hotifyApiHelper.get(uri);
  }

  downloadOutputTemplate(runId) {
    const uri = `zee/getCSVFile?runid=${runId}`;
    return hotifyApiHelper.downloadFileGET("Output-Summary", uri);
  }

  getAddplanSummary(runId) {
    const uri = `zee/getSummary?runid=${runId}`;
    return hotifyApiHelper.get(uri);
  }
  getWeeklySummaryOuput(runId) {
    const uri = `zee/weeklySummary?runid=${runId}`;
    return hotifyApiHelper.get(uri);
  }

  getProgramGenre() {
    const uri = `zee/getGenres`;
    return hotifyApiHelper.get(uri);
  }

  downloadWeeklyTemplate(runId) {
    const uri = `zee/getCSVFile?runid=${runId}`;
    return hotifyApiHelper.downloadFileGET("Daily Schedule", uri);
  }

  downloadDailyTemplate(runId) {
    const uri = `zee/downloadDailySummary?runid=${runId}`;
    return hotifyApiHelper.downloadFile("Date Wise Summary", uri);
  }

  getUniversalDownload(runId, type, name) {
    const uri = `zee/downloadFile&downloadType=${type}&runid=${runId}`;
    return hotifyApiHelper.downloadFileGET(name, uri);
  }

  postUniversalDownload(runId, clientId, planName) {
    const uri = `zee/savePlan?runid=${runId}&clientId=${clientId}&planName=${planName}`;
    return hotifyApiHelper.post(uri);
  }

  outputSummaries(clientId) {
    const uri = `zee/getSavedPlans?clientId=${clientId}`
    return hotifyApiHelper.get(uri)
  }

  getMaxGRP() {
    const uri = `zee/maxGRP`
    return hotifyApiHelper.get(uri)
  }

  getDownloadWeeklySummary(runId, fileName, type) {
    const uri = `zee/download${type}?runid=${runId}`
    return hotifyApiHelper.downloadFileGET(fileName, uri)
  }
}
