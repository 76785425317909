<template>
  <div>
    <zee-table
      :tableHeaderData="generatePlanTableHeader"
      :tableBodyData="generatePlanTableBody"
    />
    <slot></slot>
  </div>
</template>

<script>
import { Table } from "../components";
export default {
  name: "Program",
  components: {
    "zee-table": Table,
  },
  props: {
    generatePlanTableHeader: {
      type: Array,
    },
    generatePlanTableBody: {
      type: Array,
    },
  },
};
</script>

<style scoped></style>
