<template>
  <div class="zee-calendar-wrapper">
    <date-picker class="ml-3" :class="{ 'zee-calendar-wrapper': underLine }" format="YYYY-MM-DD" v-model="time2"
      :disabled-date="disabledRange" type="date" @input="getDate($event)"></date-picker>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "w-datepicker",
  props: {
    // options: {
    //   type: Array,
    //   required: true,
    // },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    selectedDate: {
      type: Date,
      // required: false,
      // default: new Date(),
    },
    placeHolder: {
      type: String,
      required: false,
      default: "Select",
    },
    labelText: {
      type: String,
      required: false,
      default: "",
    },
    underLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { DatePicker },
  data() {
    return {
      selected: this.placeHolder.length > 0 ? this.placeHolder : "Select",
      open: false,
      selectedObj: {},
      time2: null,
    };
  },
  methods: {
    getDate(e) {
      this.$emit("time2", this.time2);
      this.$emit("getDate", e);
    },
    disabledRange(date) {
      const previous = new Date(new Date().getTime());
      previous.setDate(new Date().getDate() + 6);

      const next = new Date(new Date().getTime());
      next.setDate(new Date().getDate() + 90);
      
      return date < previous || date > next;
    }
  },
  mounted() { },
};
</script>
<style scoped>
.zee-calendar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
