<template>
  <button @click="handleClick" :class="{ disableButton: isDisable }" class="zee-button">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    title: {
      type: String,
      default: "Button",
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      default: "sync",
    },
  },
  components: {},
  methods: {
    handleClick(event) {
      this.$emit("onClick", event);
    },
  },
};
</script>

<style>
.zee-button {
  width: 100px;
  height: 34px;
  margin: 1rem;
  border-radius: 8px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  border: none;
  background-color: #6c757d;
}

.zee-button:hover {
  background-color: #7f858a;
}

.disableButton {
  cursor: not-allowed !important;
  pointer-events: none;
  background-color: #dadee4 !important;
  color: #000 !important;
}
.zee-icons {
  width: 15px;
  height: 15px;
}
</style>
