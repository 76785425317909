<template>
  <div class="zee-dropdown-wrapper" :class="isNewProgram && 'zee-dropdown-wrapper-program'" :style="{ width: width }">
    <div
      class="zee-dropdown"
      :class="isNewProgram && 'zee-dropdown-program'"
      :tabindex="tabindex"
      @blur="open = false"
      :style="labelText !== '' ? 'width: 60%' : 'width:100%'"
    >
      <div
        class="zee-selected-dropdown"
        :class="{ open: open, default: selected === placeHolder, 'zee-selected-dropdown-program' : isNewProgram }"
        @click="open = !open"
        :title="selected"
      >
        {{ selected }}
      </div>
      <div
        class="zee-dropdown-items"
        :class="{ selectHide: !open }"
        :style="options.length <= 6 ? 'height: auto;' : 'height: 300px'"
      >
        <div
          class="zee-dropdown-item"
          v-for="(option, i) of options"
          :key="i"
          :class="{
            'disable-option': option.id === selectedObj.id,
            disabled: option.disable,
          }"
          @click="
            selected = option.text;
            selectedObj = option;
            open = false;
            $emit('input', option);
          "
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    options: {
      type: Array,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    placeHolder: {
      type: String,
      required: false,
      default: "Select",
    },
    labelText: {
      type: String,
      required: false,
      default: "",
    },
    selectedOption: {
      required: false,
      default: () => {},
    },
    width: {
      type: String,
      required: false,
    },
    isNewProgram : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: "",
      open: false,
      selectedObj: {},
    };
  },
  created() {
    if (this.selectedOption !== null && this.selectedOption !== undefined) {
      this.selectedObj = this.selectedOption;
      this.selected = this.selectedOption.text;
    } else {
      this.selectedObj = {};
      this.selected = this.placeHolder;
    }
  },
  beforeUpdate() {
    if (
      this.selectedOption !== null &&
      this.selectedOption !== undefined &&
      this.selectedOption !== ""
    ) {
      this.selectedObj = this.selectedOption;
      this.selected = this.selectedOption.text;
    } else {
      this.selectedObj = {};
      this.selected = this.placeHolder;
    }
  },
  mounted() {
    this.selected = this.placeHolder;
    this.$emit("input", this.selectedObj);
  },
};
</script>
<style scoped>
.default {
  font-family: ProximaNovaBold !important;
}
.zee-dropdown {
  position: relative;
  text-align: left;
  outline: none;
  height: 40px;
  line-height: 55px;
  border-radius: 8px;
  border: 0px;
  appearance: auto;
  padding: 0px;
  z-index: tabIndex;
  background-color: #6c757d;
}

.zee-dropdown-program {
  height: 30px !important;
}

.zee-dropdown:focus {
  box-shadow: none;
}
.disable-option {
  color: #313846;
  font-family: ProximaNovaBold !important;
  background-color: #6c757d;
}
.zee-selected-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: medium;
  background-color: #6c757d;
  border: 0px solid #858586;
  height: 40px;
  border-radius: 8px;
  font-size: medium;
  color: #fff;
  font-family: ProximaNovaBold;
  padding-left: 11px;
  cursor: pointer;
  user-select: none;
}

.zee-selected-dropdown-program {
  width: 240px !important;
  height: 30px !important;
  font-size: 12px !important;
  outline: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
}

.zee-selected-dropdown.open {
  border: 0px solid;
  border-radius: 6px 6px 0px 0px;
}

.zee-selected-dropdown:after {
  position: absolute;
  content: "";
  top: 20px;
  right: 20px;
  width: 10px;
  height: 16px;
  border: 4px solid transparent;
  border-color: #050505 transparent transparent transparent;
}

.zee-selected-dropdown-program.zee-selected-dropdown:after {
  top: 12px !important;
}

.zee-dropdown-items {
  color: #ffffff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: #6c757d;
  left: 0;
  overflow-y: auto;
  right: 0;
  font-size: medium;
  z-index: 99;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.5);;
}
.zee-dropdown-items::-webkit-scrollbar {
  background-color: #6c757d;
  width: 10px;
  height: 7px;
}
.zee-dropdown-items::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.zee-dropdown-items::-webkit-scrollbar-thumb {
  background: #313846;
  border: 0px none #ffffff;
  border-radius: 10px;
}
.zee-dropdown-item {
  color: #ffffff;
  padding-left: 11px;
  cursor: pointer;
  user-select: none;
  font-family: ProximaNovaRegular;
  font-weight: bold;
}
.zee-dropdown-item.disabled {
  pointer-events: none;
  font-family: ProximaNovaRegular;
  color: lightgray;
}
.zee-dropdown-item:hover {
  background-color: #6c757d;
  font-family: ProximaNovaBold;
}

.zee-dropdown-wrapper {
  width: 15rem;
  height: 40px;
  margin: 1rem;
  border-radius: 8px;
  background-color: #6c757d;
  z-index: 90;
}

.zee-dropdown-wrapper-program {
  height: 30px !important;
  margin: unset !important;
  margin-left: -10px !important;
}

/*conditionall styling*/
.selectHide {
  display: none;
}
</style>
