<template>
  <div class="zee-runtime-wrapper">
    <div v-for="(item, index) in optionsOneDetails" :key="index">
      <zee-dropdown
        :options="item.options"
        :selectedOption="item.value"
        :placeHolder="'Select'"
        @input="selectedOptionsOne($event, index)"
      />
    </div>
    <div class="zee-runtime-to-text">to</div>
    <div v-for="(item, index) in optionsTwoDetails" :key="index">
      <zee-dropdown
        :options="item.options"
        :selectedOption="item.value"
        :placeHolder="'Select'"
        @input="selectedOptionsTwo($event, index)"
      />
    </div>
  </div>
</template>

<script>
import { Dropdown } from "../components";
export default {
  name: "Runtime",
  props: {
    dropdownOptions: {
      type: Array,
    },
  },
  components: {
    "zee-dropdown": Dropdown,
  },
  data() {
    return {
      optionsOneDetails: [
        {
          name: "hrsOneDropdown",
          options: [
            { text: "700 hrs", id: "700 hrs" },
            { text: "800 hrs", id: 0 },
            { text: "900 hrs", id: 1 },
            { text: "1000 hrs", id: 2 },
            { text: "1100 hrs", id: 3 },
            { text: "1200 hrs", id: 4 },
            { text: "1300 hrs", id: 5 },
            { text: "1400 hrs", id: 6 },
            { text: "1500 hrs", id: 7 },
            { text: "1600 hrs", id: 8 },
            { text: "1700 hrs", id: 9 },
            { text: "1800 hrs", id: 10 },
            { text: "1900 hrs", id: 11 },
            { text: "2000 hrs", id: 12 },
            { text: "2100 hrs", id: 13 },
            { text: "2200 hrs", id: 14 },
            { text: "2300 hrs", id: 15 },
          ],
          value: "",
        },
        {
          name: "minsOneDropdown",
          options: [
            { text: "00 mins", id: 0 },
            // { text: "10 mins", id: 1 },
            // { text: "20 mins", id: 2 },
            { text: "30 mins", id: 3 },
            // { text: "40 mins", id: 4 },
            // { text: "50 mins", id: 5 },
          ],
          value: "",
        },
      ],
      tempOptionsTwoArray: [
        { text: "700 hrs", id: "700 hrs" },
        { text: "800 hrs", id: 0 },
        { text: "900 hrs", id: 1 },
        { text: "1000 hrs", id: 2 },
        { text: "1100 hrs", id: 3 },
        { text: "1200 hrs", id: 4 },
        { text: "1300 hrs", id: 5 },
        { text: "1400 hrs", id: 6 },
        { text: "1500 hrs", id: 7 },
        { text: "1600 hrs", id: 8 },
        { text: "1700 hrs", id: 9 },
        { text: "1800 hrs", id: 10 },
        { text: "1900 hrs", id: 11 },
        { text: "2000 hrs", id: 12 },
        { text: "2100 hrs", id: 13 },
        { text: "2200 hrs", id: 14 },
        { text: "2300 hrs", id: 15 },
      ],
      optionsTwoDetails: [
        {
          name: "hrsOneDropdown",
          options: [
            { text: "700 hrs", id: "700 hrs" },
            { text: "800 hrs", id: 0 },
            { text: "900 hrs", id: 1 },
            { text: "1000 hrs", id: 2 },
            { text: "1100 hrs", id: 3 },
            { text: "1200 hrs", id: 4 },
            { text: "1300 hrs", id: 5 },
            { text: "1400 hrs", id: 6 },
            { text: "1500 hrs", id: 7 },
            { text: "1600 hrs", id: 8 },
            { text: "1700 hrs", id: 9 },
            { text: "1800 hrs", id: 10 },
            { text: "1900 hrs", id: 11 },
            { text: "2000 hrs", id: 12 },
            { text: "2100 hrs", id: 13 },
            { text: "2200 hrs", id: 14 },
            { text: "2300 hrs", id: 15 },
          ],
          value: "",
        },
        {
          name: "minsOneDropdown",
          options: [
            { text: "00 mins", id: 0 },
            // { text: "10 mins", id: 1 },
            // { text: "20 mins", id: 2 },
            { text: "30 mins", id: 3 },
            // { text: "40 mins", id: 4 },
            // { text: "50 mins", id: 5 },
          ],
          value: "",
        },
      ],
      min: "",
      max: "",
    };
  },
  methods: {
    selectedOptionsOne(e, index) {
      let hrs, mins;
      let optionsTwoIndex = 0;
      optionsTwoIndex = e.id + 1;
      if (e.id === "700 hrs") {
        optionsTwoIndex = 0;
      }
      this.optionsOneDetails[1].options[0].id = optionsTwoIndex - 1;
      this.optionsOneDetails[1].options[1].id = optionsTwoIndex - 1;
      this.optionsTwoDetails[0].options = this.tempOptionsTwoArray.slice(
        optionsTwoIndex + 1
      );
      if (e.text === "00 mins") {
        this.optionsTwoDetails[0].options = this.tempOptionsTwoArray.slice(
          optionsTwoIndex
        );
      }
      if (e.id + 2 === this.tempOptionsTwoArray.length) {
        this.optionsTwoDetails[0].options = [{ text: "2300 hrs", id: 15 }];
      }

      if (this.optionsOneDetails[index].value != e) {
        this.optionsOneDetails[index].value = e;
      }
      this.optionsOneDetails[0].value.text !== undefined
        ? (hrs = +this.optionsOneDetails[0].value.text.replace("hrs", ""))
        : "";
      this.optionsOneDetails[1].value.text !== undefined
        ? (mins = +this.optionsOneDetails[1].value.text.replace("mins", ""))
        : "";

      let runTime = this.calculateRuntime(hrs, mins);

      // let x = min.toString().length
      // x === 3 ? min = "0" + hrs + mins : min = hrs + mins
      localStorage.setItem("startTime", runTime);
      localStorage.setItem("optionsOneDetails", JSON.stringify(this.optionsOneDetails));

      this.$emit("from", runTime);
    },
    selectedOptionsTwo(e, index) {
      let hrs, mins;
      // index = this.optionOneIndex
      // console.log(index);
      if (this.optionsTwoDetails[index].value != e) {
        this.optionsTwoDetails[index].value = e;
      }
      this.optionsTwoDetails[0].value.text !== undefined
        ? (hrs = +this.optionsTwoDetails[0].value.text.replace("hrs", ""))
        : "";
      this.optionsTwoDetails[1].value.text !== undefined
        ? (mins = +this.optionsTwoDetails[1].value.text.replace("mins", ""))
        : "";
      let runTime = this.calculateRuntime(hrs, mins);

      localStorage.setItem("endTime", runTime);
      localStorage.setItem("optionsTwoDetails", JSON.stringify(this.optionsTwoDetails));
      this.$emit("to", runTime);
    },
    calculateRuntime(hrs, mins) {
      let max = hrs + mins;
      let x = max.toString().length;
      x === 3 ? (max = `0${max.toString()}`) : (max = (hrs + mins).toString());
      return max;
    },
  },
  created() {
    let data = JSON.parse(localStorage.getItem("programDetails"));
    if (
      data[0].programRuntime.startTime !== "" &&
      data[0].programRuntime.endTime !== ""
    ) {
      this.optionsOneDetails = JSON.parse(localStorage.getItem("optionsOneDetails"));
      this.optionsTwoDetails = JSON.parse(localStorage.getItem("optionsTwoDetails"));
    }
  },
};
</script>

<style>
.zee-runtime-to-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.zee-runtime-wrapper {
  display: flex;
}

@media only screen and (max-width: 1227px) {
  .zee-runtime-wrapper {
    display: block;
  }
}
</style>
