<template>
  <div class="zee-tabs-wrapper">
    <div class="zee-tabs">
      <div class="zee-inter-tabs-container">
        <div class="zee-title-container">{{ title }}</div>
        <div class="zee-icon-container">
          <zee-icon class="zee-icons" :name="icon" />
        </div>
      </div>
    </div>
    <div v-if="show">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons";

export default {
  name: "Tabs",
  props: {
    title: {
      type: String,
      default: "Zee Tabs",
    },
    icon: {
      type: String,
      default: "sync",
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    "zee-icon": Icon,
  },
  data() {
    return {
      showSlot: false,
    };
  },
  methods: {},
};
</script>

<style>
.zee-tabs {
  width: 240px;
  height: 40px;
  margin: 2rem;
  border-radius: 8px;
  background-color: #0dcaf0;
}
.zee-title-container {
  color: #000;
  font-size: 1.8rem;
}
.zee-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.zee-icons {
  fill: #ffffff;
}
.zee-tabs-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.zee-inter-tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  padding-right: 18px;
  padding-left: 12px;
}

@media only screen and (max-width: 745px) {
}
</style>
