<template>
  <div class="zee-table-carousel">
    <zee-icon class="zee-icons" name="angle-left" />
    <div class="zee-table-carousel-text">Week 1</div>
    <zee-icon class="zee-icons" name="angle-right" />
  </div>
</template>

<script>
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons";

export default {
  name: "TableCarousel",
  components: {
    "zee-icon": Icon,
  },
  props: {
    carouselData: {},
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.zee-table-carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  background-color: green;
  height: 30px;
  border-radius: 8px;
}
.zee-table-carousel-text {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}
</style>
