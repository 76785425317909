<template>
  <b-modal hide-footer no-close-on-backdrop hide-header no-fade v-model="modalShow" size="md" class="p-0">
    <div>
      <zee-tabs title="Program Launch Date" icon="calendar">
        <div class="d-flex">
          <zee-calendar :placeHolder="'Select'" :labelText="'Date'" @getDate="getDateInput($event)" />
        </div>
      </zee-tabs>
      <zee-tabs title="Number of Weeks" icon="chevron-right">
        <zee-dropdown :options="numOfWeeksDropdown" :selectedOption="selectedWeek" :placeHolder="'Week'"
          @input="handleWeekInput($event)" class="mr-3" />
      </zee-tabs>

      <div class="text-center font-weight-bold" style="font-size: 16px">
        <!-- Your selected number of week : {{ selectedWeek.text }} -->
      </div>

      <zee-tabs title="Plan Name" icon="chevron-right">
        <zee-input @onChange="getProgramInput" />
      </zee-tabs>

      <div class="d-flex justify-content-between">
        <zee-button style="background-color: #313131" @onClick="close" title="close"></zee-button>
        <zee-button style="background-color: #6db056" @onClick="handleNextReq" :isDisable="disabledNextReqBtn"
          title="Next"></zee-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { Calendar, Tabs, Button, Dropdown, Input } from "../components";

import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();

export default {
  name: "ProgramLaunchModal",
  components: {
    // "w-dropdown": Dropdown,
    "zee-button": Button,
    "zee-tabs": Tabs,
    "zee-calendar": Calendar,
    "zee-dropdown": Dropdown,
    "zee-input": Input,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: "",
      userInput: "",
      numOfWeeksDropdown: [],
      isWeekSelected: false,
      isDateSelected: false,
      isPlanSelected: false,
      selectedWeek: {},
    };
  },
  created() {
    // this.getSummaries();
  },
  // mounted() {
  //   this.getSummaries()
  // },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    getProgramInput(e) {
      this.userInput = e;
      e.trim() !== "" ? (this.isPlanSelected = true) : (this.isPlanSelected = false);
    },
    postUserInput() {
      let creationData = this.formatDate(new Date());

      let data = JSON.parse(localStorage.getItem("programDetails"));
      data[0].programName = this.inputValue;
      data[0].programLaunchDate = this.selectedDate;
      data[0].creationDate = creationData;
      data[0].userEmail = sessionStorage.getItem("clientId");
      data[0].programNumberOfWeeks = this.selectedWeek.id;
      data[0].programTargetGRPs.min = 0;
      data[0].programTargetGRPs.max = 0;
      data[0].secondages = [];
      data[0].planName = this.userInput;
      data[0].programPTPercentage = 0;
      data[0].programName = "";
      localStorage.setItem("programDetails", JSON.stringify(data));

      zeeSolutions
        .postUserInput(...data)
        .then((res) => {
          console.log(res);
          sessionStorage.setItem("run_id", res.runid);
          this.$router.push(`/solutions/zeetv/program-plans`);
        })
        .catch((err) => {
          alert(err);
        });
    },
    getDateInput(e) {
      let date = this.formatDate(e);
      let todaysDate = this.formatDate(new Date());
      this.selectedDate = date;
      let x = this.getWeeksDiff(new Date(date), new Date(todaysDate));

      this.numOfWeeksDropdown = [];

      for (var i = 0; i < x; i++) {
        this.numOfWeeksDropdown.push({ id: i + 1, text: i + 1 });
      }
      this.isDateSelected = true;
      this.isWeekSelected = false;
      this.isPlanSelected = false;
      this.selectedWeek = {};
    },

    handleWeekInput(e) {
      this.isWeekSelected = false;
      this.selectedWeek = e;
      if (e.id !== undefined) {
        this.isWeekSelected = true;
      }
    },
    getWeeksDiff(startDate, endDate) {
      const msInWeek = 1000 * 60 * 60 * 24 * 7;

      return Math.round(Math.abs(endDate - startDate) / msInWeek);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    handleNextReq() {
      sessionStorage.setItem("weeks", this.selectedWeek.id);
      sessionStorage.setItem(
        "programLaunchDate",
        JSON.stringify({
          date: this.selectedDate,
          weekNumber: this.selectedWeek.id,
        })
      );
      sessionStorage.setItem("show_add_program_screen", false);
      this.postUserInput();
    },
  },
  computed: {
    disabledNextReqBtn() {
      return !this.isDateSelected || !this.isWeekSelected || !this.isPlanSelected;
    },
  },
};
</script>
<style>
.modal.show .modal-dialog {
  margin-top: 0px;
}
</style>
<style scoped>
input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  color: #222a37;
  font-size: 16px;
  font-family: ProximaNovaBold;
}

.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}

input:focus {
  background-color: #eff5ff;
  outline: none;
}

.title {
  margin-top: 70px;
  font-size: 32px;
  font-family: ProximaNovaBold;
  line-height: 1.56;
  color: #222a37;
}

.subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 1.38;
  color: #9aafd4;
  margin-bottom: 24px;
}

.bg-red {
  background-color: #ff5a5a !important;
}

.change-mind {
  margin-top: 26px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #050505;
  text-decoration-line: underline;
  cursor: pointer;
}

.btn-effect {
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 208px !important;
  height: 60px !important;
  margin-top: 24px !important;
  object-fit: contain;
}

.btn-effect-disabled {
  opacity: 0.2;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
