<template>
  <div style="display: flex; justify-content: space-between; width: 200%; gap: 13%">
    <div style="width: 100px">
      <zee-multi-dropdown
        :maximum="100"
        :options="summaryOptionsSelected1"
        @getSelectedHeader="getSelectedOptions1"
        :placeholder="'Smaller Edits'"
      />
    </div>
    <div style="width: 100px">
      <zee-multi-dropdown
        :maximum="100"
        :options="summaryOptionsSelected2"
        @getSelectedHeader="getSelectedOptions2"
        :placeholder="'Medium Edits'"
      />
    </div>
    <div style="width: 100px">
      <zee-multi-dropdown
        :maximum="100"
        :options="summaryOptionsSelected3"
        @getSelectedHeader="getSelectedOptions3"
        :placeholder="'Larger Edits'"
      />
    </div>
  </div>
</template>

<script>
import { MultiDropdown } from "../components";
export default {
  name: "ThreeDropDown",
  props: {
    defaultValue : {
     type: Array,
     default: function() {return []; } 
    }
  },
  components: {
    "zee-multi-dropdown": MultiDropdown,
  },
  data() {
    return {
      selectedOptions1: [],
      selectedOptions2: [],
      selectedOptions3: [],
      summaryOptionsSelected1: [
        {
          id: "05",
          name: "05 sec",
          value: false,
          $isDisabled: false,
          key: "05 sec",
        },
        {
          id: "10",
          name: "10 sec",
          value: false,
          $isDisabled: false,
          key: "10 sec",
        },
        {
          id: "15",

          name: "15 sec",
          value: false,
          $isDisabled: false,
          key: "15 sec",
        },
      ],
      summaryOptionsSelected2: [
        {
          id: "20",

          name: "20 sec",
          value: false,
          $isDisabled: false,
          key: "20 sec",
        },
        {
          id: "25",

          name: "25 sec",
          value: false,
          $isDisabled: false,
          key: "25 sec",
        },
        {
          id: "30",
          name: "30 sec",
          value: false,
          $isDisabled: false,
          key: "30 sec",
        },
      ],
      summaryOptionsSelected3: [
        {
          id: "40",
          name: "40 sec",
          value: false,
          $isDisabled: false,
          key: "40 sec",
        },
        {
          id: "50",

          name: "50 sec",
          value: false,
          $isDisabled: false,
          key: "50 sec",
        },
        {
          id: "60",
          name: "60 sec",
          value: false,
          $isDisabled: false,
          key: "60 sec",
        },
      ],
    };
  },
  methods: {
    getSelectedOptions1(e1) {
      this.selectedOptions1 = e1;
      // if(e1.length != 0){
        // console.log("in the three drop down", e1)
        this.finalOptions();
      // }
    },
    getSelectedOptions2(e2) {
      this.selectedOptions2 = e2;
      // if(e2.length != 0){
        // console.log("in the three drop down", e2)
        this.finalOptions();
      // }
    },
    getSelectedOptions3(e3) {
      this.selectedOptions3 = e3;
      // if(e3.length != 0){
        // console.log("in the three drop down", e3)
        this.finalOptions();
      // }
    },
    finalOptions() {
      // console.log("this is updated in three",[...this.selectedOptions1,...this.selectedOptions2, ...this.selectedOptions3])
      let seletedHeader = [
        ...this.selectedOptions1,
        ...this.selectedOptions2,
        ...this.selectedOptions3,
      ];
      this.$emit("getSelectedHeader", seletedHeader);
    },
  },
  created() {
    // console.log(this.defaultValue, "DSFdsf",  this.summaryOptionsSelected1)
    this.summaryOptionsSelected1 = this.summaryOptionsSelected1.map((item) => {
      for(let i = 0; i < this.defaultValue.length; i++) {
        if(item.id == +this.defaultValue[i]) {
            return {
            ...item,
            value: true
          }
        }
      }
      return item;
    })
    
    this.summaryOptionsSelected2 = this.summaryOptionsSelected2.map((item) => {
      for(let i = 0; i < this.defaultValue.length; i++) {
        if(item.id == +this.defaultValue[i]) {
            return {
            ...item,
            value: true
          }
        }
      }
      return item;
    })

    this.summaryOptionsSelected3 = this.summaryOptionsSelected3.map((item) => {
      for(let i = 0; i < this.defaultValue.length; i++) {
        if(item.id == +this.defaultValue[i]) {
            return {
            ...item,
            value: true
          }
        }
      }
      return item;
    })
  }
};
</script>
