<template>
  <div>
    <div class="zee-select-table-header-wrapper" v-for="(item, index) in customInputData" :key="index">
      <div v-for="(item1, index) in item.columns" :key="index">
        <table class="zee-select-table-column">
          <tr>
            <th :class="{ expand: item1.expand }" class="zee-select-table-elem">
              <input :class="{
                active: index === 0 && input,
                total: item1.id[0] === 5 && item1.total,
              }" :disabled="index === 0 && !input" :value="item1.value" :placeholder="item1.name"
                @input="handleInput($event, index, item1.name, item1.id)"
                oninput="this.value = this.value.replace(/[^0-9]/g,'')" class="zee-custom-table-input-1 p-0 m-0" />
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomInputTable",
  props: {
    customInputData: {
      type: Array,
    },
    input: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleInput(event, index, name, id) {
      let data = { event, index, name, id };
      this.$emit("onInput", data);
    },
    handleInputLabels() { },
  },
  created() { },
};
</script>

<style>
/* .zee-custom-table-input-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem;
} */
.zee-custom-table-input-component-wrapper {}

.zee-custom-table-input-1 {
  height: 30px;
  width: 100%;

  border-radius: 8px;
  font-size: 14px;
  background-color: #313131;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  outline: none;
  border: none;
}

::placeholder {
  color: #ffffff;
  opacity: 1;
}

.zee-custom-table-input-label {
  height: 25px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  background-color: green;
  color: #ffffff;
  font-weight: bold;
  outline: none;
  border: none;
}

.active {
  background-color: #0dcaf0;
  font-size: 12px;
}

.total {
  background-color: #6db056;
  color: #ffffff;
  pointer-events: none;
  color: #aaa;
}

/* .red {
  background-color: #ff5252;
} */
</style>
