<template>
  <div>
    <div class="zee-output-table-parent-wrapper">
      <!--header data-->
      <div class="zee-output-data-header mt-2" v-if="carouselData !== ''">
        <div
          class="zee-filter-by-tab"
          :style="{ 'background-color': carouselData3Color }"
        >
          <div class="Ellipse-921" @click="$emit('handleBackDataChange')">
            <!-- <zee-icon class="zee-icons-1" :name="'chevron-right'" /> -->
          </div>
          {{ carouselData }}
          <div class="Ellipse-92" @click="$emit('handleDataChange')">
            <zee-icon class="zee-icons-1" :name="'chevron-left'" />
          </div>
        </div>
      </div>
      <div class="zee-output-data-header mt-2">
        <div class="zee-table-topHeader">
          <div
            class="zee-table-topHeader-data"
            v-for="(item, i) in tableTopHeaderData"
            :key="i"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in tableHeaderData"
        :key="index"
        class="zee-output-data-header mt-2"
      >
        <table
          class="zee-output-table-wrapper"
          v-for="(item, index) in item.columns"
          :key="index"
        >
          <tr>
            <th
              class="zee-output-table-elem"
              :style="{ 'background-color': item.color }"
              :class="{ showOnlyOne: expandFirst }"
            >
              <!-- <zee-icon class="zee-table-icons" :name="headerTableLeftIcon" /> -->
              <div
                class="d-flex justify-content-center align-items-center"
                :style="{ color: item.fontColor }"
              >
                {{ item.name }}
              </div>
              <span class="tooltiptext" v-if="item.startDate != undefined">
                <div>Start Date: {{item.startDate}}</div>
                <div>End Date: {{item.endDate}}</div>
                <div>Number of days: {{item.numOfDays}}</div>
              </span>
              <!-- <zee-icon class="zee-table-icons" :name="headerTableRightIcon" /> -->
            </th>
          </tr>
        </table>
      </div>
      <!--body data-->
      <div>
        <div
          v-for="(item, index) in tableBodyData"
          :key="index"
          class="zee-output-data-body"
          id="zeeOutputBody"
        >
          <table
            class="zee-output-body-wrapper"
            v-for="(item, index) in item.columns"
            :key="index"
          >
            <tr>
              <th
                class="zee-output-table-body-elem"
                :style="{ 'background-color': item.color }"
              >
                <div class="text-white">
                  {{ item.name }}
                </div>
              </th>
            </tr>
          </table>
        </div>
        <!-- <div class="d-flex justify-content-end mt-4">
        <div class="zee-output-summary-tab">
          <div class="zee-output-summary-tab-total">Total</div>
          <div class="zee-totalContentWrapper">
            <div class="zee-totalContent" v-for="(item,i) in tableBodyData[0].columns" :key="i">{{i}}</div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons";

export default {
  name: "OutputTable",
  props: {
    carouselData3Color: {
      type: String,
    },
    carouselData: {
      type: String,
      default: "",
      // default:() => {},
    },
    tableTopHeaderData: {
      type: Array,
    },
    tableHeaderData: {
      type: Array,
    },
    tableBodyData: {
      type: Array,
    },
    headerTableLeftIcon: {
      type: String,
    },
    headerTableRightIcon: {
      type: String,
    },
    bodyTableLeftIcon: {
      type: String,
    },
    bodyTableRightIcon: {
      type: String,
    },
    expandFirst: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "zee-icon": Icon,
  },
  data () {
    return {
      uphere: false
    }
  }
};
</script>

<style>
.zee-output-data-header {
  display: flex;
  justify-content: space-around;
  /* gap: 4.5rem; */
  width: 100%;
}
.zee-output-data-body {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem;
  padding-left: 0%;
  padding-right: 0%;
}
.zee-output-table-wrapper {
  margin: 1rem;
}
.zee-output-table-parent-wrapper {
}
.zee-output-table-body-elem {
  width: 138px;
  height: 30px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  /* color: #ffffff; */
  color: rgb(35, 32, 32);
  font-weight: bold;
  outline: none;
  border: none;
}
.zee-output-table-elem {
  position: relative;
  width: 138px;
  height: 30px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  /* color: #ffffff; */
  color: rgb(35, 32, 32);
  font-weight: bold;
  outline: none;
  border: none;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 30px;
  font-size: 12px;
  border-radius: 8px;
  padding: 0px 20px 0px 20px; */
}
.zee-output-body-wrapper {
  margin: 1rem;
}
.zee-table-icons {
  background-color: #6c757d;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  fill: #ffffff;
}
.zee-table-topHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.zee-table-topHeader-data {
  width: calc(39% + 163px);
  margin-left: 1rem;
  margin-right: 1rem;
  /* width: 306px; */
  background-color: #6db056;
  height: 25px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 130%;
  padding: 0.4% 0.4% 0.4% 0.4%;
}
.zee-filter-by-tab {
  /* width: 52%; */
  width: calc(86% + 163px);
  /* background-color: #fffba7; */
  background-color: #6db056;
  height: 25px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 130%;
  padding: 0.4% 0.4% 0.4% 0.4%;
  margin-left: 2%;
  margin-right: 2%;
}
.Ellipse-92 {
  /* width: 3.1%;
  height: 133%; */
  width: 18px;
  height: 18px;
  flex-grow: 0;
  transform: rotate(-180deg);
  background-color: #6c757d;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ellipse-921 {
  width: 18px;
  height: 18px;
  flex-grow: 0;
  transform: rotate(-180deg);
  /* background-color: #6c757d; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zee-icons-1 {
  fill: #ffffff;
  width: 10px;
  height: 10px;
}
.tooltiptext {
  visibility: hidden;
  width: 150%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -100px;
}

.zee-output-table-elem .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.zee-output-table-elem:hover .tooltiptext {
  visibility: visible;
}

/* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
} */
/* .zee-output-table-elem .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.zee-output-table-elemp .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.zee-output-table-elem:hover .tooltiptext {
  visibility: visible;
} */
</style>
