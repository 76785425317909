<template>
  <div class="zee-input-wrapper">
    <input :type="type" :class="{ activeInput: !this.active }" :placeholder="placeholder" v-model="input"
      @input="handleInput" ref="zeeInput" class="zee-input" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    placeholder: {
      type: String,
    },
    type: {
      type: String,
    },
    value: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      input: "",
      active: false,
    };
  },
  methods: {
    handleInput(e) {
      let input = e.target.value.trim();
      input !== "" && input !== undefined ? (this.active = false) : (this.active = true);
      this.$emit("onChange", e.target.value);
    },
  },
  created() {
    this.input = this.value;

    document.addEventListener("keydown", (() => {
       console.log("");
    }))
  },
};
</script>

<style>
.activeInput {
  background-color: #6c757d !important;
}

.zee-input {
  height: 40px;
  width: 180px;
  margin: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  padding-left: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #bcc9d6;
}

.zee-input-wrapper {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 745px) {
  .zee-input-wrapper {
    display: block;
  }
}
</style>
