<template>
  <div>
    <div class="zee-custom-table-input-wrapper" v-for="(item, index) in customInputHeaderData" :key="index">
      <div v-for="(item1, index) in item.columns" :key="index">
        <table class="zee-custom-table-input-component-wrapper" :style="index === 0 ? 'margin-right: 0' : ''">
          <tr id="parent_xyz">
            <div v-if="index === 0" :style="`width: 245px; ${index == 2 ? 'width: 138px !important' : ''}`" class="p-0"></div>
            <input :disabled="true" :class="{
              active: index === 0,
              'zee-custom-table-input-header': index === 0
            }" :placeholder="item1.name" :style="`height: 30px;background: #6c757d;${index == 2 ? 'width: 138px !important' : ''}`" class="zee-custom-table-input" />
          </tr>
        </table>
      </div>
      <div>
        <table class="zee-custom-table-input-component-wrapper">
          <tr id="parent_xyz">
            <zee-button @onClick="removeItem(i, item)" :title="'Action'" />
          </tr>
        </table>
      </div>
    </div>
    <div class="zee-custom-table-input-wrapper" v-for="(item, i) in customInputData" :key="item.index || i">
      <div v-for="(item1, index) in item.columns" :key="index">
        <table class="zee-custom-table-input-component-wrapper">
          <tr id="parent_xyz">
            <div v-if="item1.type === 'dropdown'">
              <div v-if="item1.innerType === 'multi'">
                <zee-multi-dropdown :isProgramTable="true" :maximum="100" :tabindex="index" :resetAfter="true"
                  :options="item1.options" @getSelectedHeader="getSelectedOptions($event, i)"
                  :placeholder="'Select ACDs'" />
              </div>
              <div v-else>
                <zee-dropdown :tabindex="index" style="width: 245px; margin-left: -10px" :options="item1.options"
                  :selectedOption="item1.value" @input="handleDropdown($event, index, item1.name, item1.id)"
                  :isNewProgram="true" />
              </div>
            </div>
            <div v-else>
              <input :class="{
                active: index === 0 && input,
                total: item1.id[0] === 5 && item1.total,
                red: item1.id[0] === 5 && !item1.total,
              }" :disabled="(index === 0 && !input) || item1.isDisable" :placeholder="index == 1 ? item1.name : ''"
                :value="item1.value" class="zee-custom-table-input"
                @input="handleInput($event, index, item1.name, item1.id)" />
            </div>
          </tr>
        </table>
      </div>
      <div>
        <table class="zee-custom-table-input-component-wrapper" style="font-size: 12px !important">
          <tr id="parent_xyz">
            <zee-button @onClick="removeItem(i, item)" :title="'Remove'" />
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown, MultiDropdown, Button } from "../components";
export default {
  name: "CustomInputTable",
  components: {
    "zee-dropdown": Dropdown,
    "zee-multi-dropdown": MultiDropdown,
    "zee-button": Button,
  },
  props: {
    customInputData: {
      type: Array,
    },
    input: {
      type: Boolean,
      default: false,
    },
    customInputHeaderData: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleInput(event, index, name, id) {
      let data = { event, index, name, id };
      this.$emit("onInput", data);
    },
    handleDropdown(event, index, name, id) {
      let data = { event, index, name, id };
      this.$emit("onSelect", data);
      // let data = { event, selected };
      // this.$emit("onSelect", data);
    },
    handleInputLabels() { },
    getSelectedOptions(e, index) {
      this.$emit('getSelectedOptions', { e, index })
    },
    removeItem(index, item) {
      this.$emit("removeItem", { index: index, item: item })
    }
  },
  created() {
    console.log(this.customInputData, "Inside Components");
  },
};
</script>

<style>
.zee-custom-table-input-wrapper {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  margin: 1rem;
}

.zee-custom-table-input-component-wrapper {
  margin: 1rem;
}

.zee-custom-table-input {
  /* margin-top: 10px; */
  /* width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  background-color: #313131;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  outline: none;
  border: none; */

  max-width: 138px;
  width: 100%;
  padding: 0 12px;
  height: 30px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  background-color: #313131;
  color: #ffffff;
  font-weight: bold;
  outline: none;
  border: none;
}

::placeholder {
  color: #ffffff;
  opacity: 1;
}

.zee-custom-table-input-label-1 {
  height: 50px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  background-color: green;
  color: #ffffff;
  font-weight: bold;
  outline: none;
  border: none;
}

.zee-temp-header {
  width: 260px;
  background-color: #313131;
  height: 40px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.zee-custom-table-input-header {
  max-width: unset;
  width: 100%;
  margin-left: -1rem;
  margin-right: 0;
}

.active {
  background-color: #0dcaf0;
  font-size: 12px;
}

.total {
  background-color: #6db056;
  color: #ffffff;
  pointer-events: none;
  color: #aaa;
}

/* .red {
  background-color: #ff5252;
} */
</style>
