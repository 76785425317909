var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.customInputHeaderData),function(item,index){return _c('div',{key:index,staticClass:"zee-custom-table-input-wrapper"},[_vm._l((item.columns),function(item1,index){return _c('div',{key:index},[_c('table',{staticClass:"zee-custom-table-input-component-wrapper",style:(index === 0 ? 'margin-right: 0' : '')},[_c('tr',{attrs:{"id":"parent_xyz"}},[(index === 0)?_c('div',{staticClass:"p-0",style:(`width: 245px; ${index == 2 ? 'width: 138px !important' : ''}`)}):_vm._e(),_c('input',{staticClass:"zee-custom-table-input",class:{
            active: index === 0,
            'zee-custom-table-input-header': index === 0
          },style:(`height: 30px;background: #6c757d;${index == 2 ? 'width: 138px !important' : ''}`),attrs:{"disabled":true,"placeholder":item1.name}})])])])}),_c('div',[_c('table',{staticClass:"zee-custom-table-input-component-wrapper"},[_c('tr',{attrs:{"id":"parent_xyz"}},[_c('zee-button',{attrs:{"title":'Action'},on:{"onClick":function($event){return _vm.removeItem(_vm.i, item)}}})],1)])])],2)}),_vm._l((_vm.customInputData),function(item,i){return _c('div',{key:item.index || i,staticClass:"zee-custom-table-input-wrapper"},[_vm._l((item.columns),function(item1,index){return _c('div',{key:index},[_c('table',{staticClass:"zee-custom-table-input-component-wrapper"},[_c('tr',{attrs:{"id":"parent_xyz"}},[(item1.type === 'dropdown')?_c('div',[(item1.innerType === 'multi')?_c('div',[_c('zee-multi-dropdown',{attrs:{"isProgramTable":true,"maximum":100,"tabindex":index,"resetAfter":true,"options":item1.options,"placeholder":'Select ACDs'},on:{"getSelectedHeader":function($event){return _vm.getSelectedOptions($event, i)}}})],1):_c('div',[_c('zee-dropdown',{staticStyle:{"width":"245px","margin-left":"-10px"},attrs:{"tabindex":index,"options":item1.options,"selectedOption":item1.value,"isNewProgram":true},on:{"input":function($event){return _vm.handleDropdown($event, index, item1.name, item1.id)}}})],1)]):_c('div',[_c('input',{staticClass:"zee-custom-table-input",class:{
              active: index === 0 && _vm.input,
              total: item1.id[0] === 5 && item1.total,
              red: item1.id[0] === 5 && !item1.total,
            },attrs:{"disabled":(index === 0 && !_vm.input) || item1.isDisable,"placeholder":index == 1 ? item1.name : ''},domProps:{"value":item1.value},on:{"input":function($event){return _vm.handleInput($event, index, item1.name, item1.id)}}})])])])])}),_c('div',[_c('table',{staticClass:"zee-custom-table-input-component-wrapper",staticStyle:{"font-size":"12px !important"}},[_c('tr',{attrs:{"id":"parent_xyz"}},[_c('zee-button',{attrs:{"title":'Remove'},on:{"onClick":function($event){return _vm.removeItem(i, item)}}})],1)])])],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }